/** For partners offering free online wills (the value of partner.offersDiscountedWills
 * from Contentful is false), the PartnerOnline and PartnerOnlineAndTelephone templates
 * will use the below default text, instead of any custom values that might be passed
 * from Contentful.
 */
export const FREE_WILLS_DEFAULT_TEXT = {
  BANNER:
    'Welcome! We’ve partnered with Farewill so you can write your will for free.',
  HERO_TITLE: 'Write your will for free today',
  HERO_SUBTITLE: 'Write your will and take care of what really matters.',
} as const
